import React from 'react';
import styled from 'styled-components';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div` 
  position: relative;
  grid-template-columns: repeat(12,1fr);
  display: grid;
`

const Inner = styled.div`
  grid-column: 1 / span 12;

  ${media.large`
    grid-column: 5 / span 6;
  `}
`

const InfoLayout = React.forwardRef((props,ref) => {
  const { children } = props;
  return (
    <Wrapper ref={ref}>
      <Inner>
        { children }    
      </Inner>
    </Wrapper>
  )
})

export default InfoLayout;