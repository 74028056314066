import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll'
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import HtmlParser from '../HtmlParser/HtmlParser';

const Wrapper = styled.section`
  border-top: 1px solid #383B23;
  padding: 20px 0 0;
  margin: 0 0 40px;
  color: #383B23;
`


const InfoTextGroup = (props) => {
  const { children, name} = props;

  const getLayout = (children, name) => {
    if(name) {
      return (
      <Element name={name}>
        <Wrapper>
          <HtmlParser>
            { children }
          </HtmlParser>
        </Wrapper>
      </Element> )
    } 

    return ( 
      <Wrapper>
        <HtmlParser>
          { children }
        </HtmlParser>
      </Wrapper>
    )
  }
  return (
    <>
      { getLayout(children, name)}
    </>
  )
}

export default InfoTextGroup;