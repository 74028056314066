import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import slugify from "@sindresorhus/slugify";
import { Link, Element } from 'react-scroll'
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';


const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 60px 0 25px;
  z-index: 2;
  opacity: ${props => props.active ? '1' : '0'};
  transition: opacity .1s ease;

  ${media.large`
    position: fixed;
    height: 100vh;
    left: 20px;
    align-items: flex-start;
    margin: 80px 0 0;
  `}
`

const Items = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: info-counter;
  ${fontstack.default}
  ${type('caption')}
  color: #383B23;

  ${media.large`
    ${type('body')}
  `}
  
`

const Item = styled.li`
  counter-increment: info-counter;
  position: relative;
  display: flex;
  opacity: ${props => props.active ? '1' : '0.4'};
  cursor: pointer;
  transition: opacity .2s ease;
  
  &:not(:last-of-type) {
    margin: 0 0 5px;

    ${media.large`
      margin: 0 0 10px;
    `}
  }

  & > span {
    flex: 1;
  }

  &:before {
    content: counter(info-counter) ". ";
    width: 20px;
    ${type('caption')} 
  }
`

const InfoMenu = React.forwardRef((props,ref) => {
  const { items, active } = props;
  const [ Active, setActive ] = useState();

  useEffect(() => {
    setActive(null);
  },[])
  return (
    <Wrapper active={active}>
      <Items ref={ref}>
        { items.map((item, index) => {
          return (
            <Item active={index === Active ? true : false}><Link activeClass="active" to={slugify(item)} spy={true} smooth={true} duration={200} offset={-100} onSetActive={() => { setActive(index)}}><span>{item}</span></Link></Item>
          )
        })}
      </Items>
    </Wrapper>
  )
})

export default InfoMenu;