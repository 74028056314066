import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import HtmlParser from '../HtmlParser/HtmlParser';
import slugify from "@sindresorhus/slugify";
import InfoMenu from "./InfoMenu";
import InfoTextGroup from "./InfoTextGroup";
import InfoAccordionGroup from './InfoAccordionGroup';
import InfoLayout from "./InfoLayout";
import InfoPageTitle from "./InfoPageTitle";
import Section from '../Section/Section';


const OverFlowHidden = styled.div`
  overflow: hidden;
`

const InfoWrapper = ({title,data}) => {
  const MainEl = useRef();
  const MenuEl = useRef();
  const [ MenuActive, setMenuActive ] = useState(true); 

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, []);

  const onScroll = () => {
    const boundsMenu = MenuEl.current.getBoundingClientRect();
    const boundsMain = MainEl.current.getBoundingClientRect();

    if(boundsMain.bottom < boundsMenu.bottom) {
      setMenuActive(false);
    } else {
      setMenuActive(true);
    }
  }

  const menuItems = data.reduce((acc,curr) => {
    if(curr.item) {
      acc.push(curr.item);
    }
    return acc;
  },[])

  return (
    <OverFlowHidden>
      <Section none>
        <InfoMenu items={menuItems} ref={MenuEl} active={MenuActive}/>
        <InfoLayout ref={MainEl}>
          <InfoPageTitle><HtmlParser>{title}</HtmlParser></InfoPageTitle>
          {data ? data.map((block,index) => {
            return block.content ? 
              <InfoTextGroup name={block.item ? slugify(block.item) : null}>{block.content}</InfoTextGroup> 
              : block.questions ? <InfoAccordionGroup name={block.item ? slugify(block.item) : null} index={index+1} title={block.item} questions={block.questions} /> : null;
          }) : null }
        </InfoLayout>
      </Section>
    </OverFlowHidden>
  )
}

export default InfoWrapper;