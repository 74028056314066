import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  margin: 0 0 40px;
  ${media.large`
    margin: 300px 0 150px;
  `}
`

const Title = styled.h1` 
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  color: #383B23;
  margin: 0;

  ${media.large`
    ${type('large')}
    font-weight: 300;
  `}
`

const InfoPageTitle = (props) => {
  const { children } = props;

  return (
    <Wrapper>
      <Title>{ children }</Title>
    </Wrapper>
  )
}

export default InfoPageTitle;