import React from "react"
import { graphql } from "gatsby"
import { Seo } from "../components/seo"
import { Layout } from "../components/Layout/layout"
import InfoWrapper from "../components/Info/InfoWrapper"

export default function FaqPage({
  data: { faqs },
}) {
  const faqsData = faqs.data.body.reduce((acc,curr) => {
    const primary = curr.primary;
    const item = primary.menu_title;
    const content = primary.content.html;
    const questions = curr.items.reduce((acc,curr) => {

      const question = { question: curr.question, answer: curr.answer.html};

      if(curr.question) {
        acc.push(question);
      }
      return acc;
    },[]);

    acc.push(Object.assign({content: content},
      !questions || questions.length === 0 ? null : {questions},
      item === null ? null : {item},
    ))

    return acc;
  },[])
  return (
    <Layout navBorder>
      <Seo
          title='FAQs'
          description='We’re here to help - ask us anything big or small.'
        />
      <InfoWrapper title={"We're here to help—<br/>ask us anything big or small."} data={faqsData}/>
    </Layout>
  )
}

export const query = graphql`
  query {
    faqs: prismicTermsOfService(id: {eq: "63dc368c-9606-57dc-bdbb-293d6a36d89d"}) {
      data {
        body {
          ... on PrismicTermsOfServiceDataBodyContentGroup {
            id
            primary {
              content {
                html
              }
              menu_title
            }
            items {
              question
              answer {
                html
              }
            }
          }
        }
      }
      id
    }
  }
`