import React, { useRef, useState } from 'react';
import { gsap } from "gsap";
import styled from 'styled-components';
import { Element } from 'react-scroll'
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import HtmlParser from '../HtmlParser/HtmlParser';
import { Accordion, AccordionItem } from '../Accordion/Accordion';

const Wrapper = styled.section`
  border-top: 1px solid #383B23;
  display: flex;
  margin: 0 0 60px;

  ${media.large`
    margin: 0 0 100px;
  `}
`

const IndexWrapper = styled.div`
  grid-column: 1 / span 1;
  padding: 20px 20px 0 0;

  ${media.large`
    padding: 20px 0 0 0;
  `}
`

const IndexLabel = styled.div`
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500;
  color: #383B23;

  ${media.large`
    ${type('body')}
    font-weight: 500;
  `}
`

const AccordionWrapper = styled.div`
  position: relative;
  grid-column: 2 / span 11;

  ${media.large`
    flex: 1;
  `}
`

const AccordionInfoItem = styled(AccordionItem)` 
  text-transform: initial;
  font-weight: 500;
`

const AccordionRails = styled.div`
    position: absolute;
    height: 100%;
    width: 33%;
    left: 0;
    top: 0;
`

const AccordionInfo = styled(Accordion)` 

`

const ItemCircle = styled.div`
  width: 13px;
  height: 13px;
  transform: translate(10px, 22px);
  background: #383B23;
  border-radius: 50%;
  opacity: 0;
  
  ${media.large`
    opacity: ${props => props.active ? '1' : '0'};
    transition: opacity .2s ease;
  `}
 
`

const InfoAccordionGroup = (props) => {
  const {index,questions,name} = props;

  const [ Active, setActive ] = useState();
  const wrapperEl = useRef();
  const circleEl = useRef();

  const onPosFired = (bounding) => {
    const wrapperBounding = wrapperEl.current.getBoundingClientRect();
    const y = bounding.y - wrapperBounding.y;
    
    gsap.to(circleEl.current, {
      y: y + 22,
      duration: 1,
      ease: 'elastic.out(1, 0.95)'
    });
  }

  return (
    <Element name={name}>
    <Wrapper ref={wrapperEl} onMouseEnter={()=>setActive(true)} onMouseLeave={()=>setActive(false)}>
      <IndexWrapper>
        <IndexLabel>{index}.</IndexLabel>
      </IndexWrapper>
      <AccordionWrapper>
        <AccordionRails>
          <ItemCircle ref={circleEl} active={Active}/>
        </AccordionRails>
        <AccordionInfo>
          {questions ? questions.map((data) => {
            return (
                <AccordionInfoItem opacitynone label={data.question} posCallback={onPosFired} iconCol>{data.answer}</AccordionInfoItem>
            )
          }) : null}
        </AccordionInfo>
      </AccordionWrapper>
    </Wrapper>
  </Element>
  )
}

export default InfoAccordionGroup;